import $ from 'jquery'; // Jquery
import './utils.js'; // Дополнительная логика (Модалки, якорные ссылки)
import './utilities/modals.js'; // Модальные окна
import './utilities/autoheight.js'; // Автовыравнивание элементов по высоте

// Импорт вспомогательных модулей для develop-среды
if (process.env.NODE_ENV === 'development') {
  import('./uikit.js').then((uikit) => {
    window.displayRootVariable = uikit.displayRootVariable;
    window.toggleDevPanel = uikit.toggleDevPanel;
  })
}

window.jQuery = $;
window.$ = $;

// Проверка инпутов на пустоту или если номер не до конца заполнен
$("input[required],textarea[required]").on("blur", function () {
  if ($(this).val().length == 0) {
    $(this).closest(".input_container").addClass("error");
  } else {
    $(this).closest(".input_container").removeClass("error");
  }
});
$("input[name*=phone]").on("blur", function () {
  if ($(this).val().length != $(this).attr("maxlength")) {
    $(this).closest(".input_container").addClass("error");
  } else {
    $(this).closest(".input_container").removeClass("error");
  }
});

// UI-кит и панель навигации
if (process.env.NODE_ENV === 'development') {
  window.onload = () => {
    window.displayRootVariable();
    window.toggleDevPanel();

    setTimeout(() => {
      document.getElementById('devpanel_button').click();
    }, 500);
  };
}